$mobileStepper: 1000;
$fab: 1050;
$speedDial: 1050;
$appBar: 1100;
$menu: 1150;
$drawer: 1200;
$modal: 1300;
$snackbar: 1400;
$tooltip: 1500;

// For JS Imports
$exports: (
  mobileStepper: $mobileStepper,
  fab: $fab,
  speedDial: $speedDial,
  appBar: $appBar,
  menu: $menu,
  drawer: $drawer,
  modal: $modal,
  snackbar: $snackbar,
  tooltip: $tooltip,
);
